<template>
  <div class="content">
    
    <div style="clear:both;width:100%;height:1px"></div>
       <div class="list">
      <div class="list_title list_title2">
        <span class="active">做任务赚积分</span>
      </div>
      <div class="list_omg">
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.video}}</span><span>积分</span></div>
          <h2>学习新课程</h2>
          <span>{{info.is_video==1?1:0}}/1</span>
          <div class="btn btn2" v-if="info.is_video==1">已完成</div>
          <div class="btn" v-else @click="toVideo">去完成</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.task}}</span><span>积分</span></div>
          <h2>提交作业</h2>
          <span>{{info?info.task_ok_num:0}}/1</span>
          <div class="btn btn2" v-if="info.task_ok_num>=1">已完成</div>
          <div class="btn" v-else @click="toVideo">去完成</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.goods}}</span><span>积分</span></div>
          <h2>获得优秀作业</h2>
          <span>{{info.task_good_num}}/1</span>
          <div class="btn btn2" v-if="info.task_good_num>=1">已获得</div>
          <div class="btn" v-else @click="toVideo">去获得</div>
        </div>
       <div class="list_omg_one">
          <div class="yuan"><span>{{info.rankthree}}</span><span>积分</span></div>
          <h2>获得“造字达人”称号</h2>
          <span>参加周末卷卷堂</span>
          <div class="btn btn2" v-if="info.daren==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
        <div class="list_omg_one">
          <div class="yuan"><span>{{info.ranktwo}}</span><span>积分</span></div>
          <h2>获得“造字大师”称号</h2>
          <span>参加周末卷卷堂</span>
          
          <div class="btn btn2" v-if="info.dashi==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
         <div class="list_omg_one">
          <div class="yuan"><span>{{info.rankone}}</span><span>积分</span></div>
          <h2>获得“造字王者”称号</h2>
          <span>参加周末卷卷堂</span>
          <div class="btn btn2" v-if="info.wangzhe==1">已获得</div>
          <div class="btn" v-else @click="toArrondi">去获得</div>
        </div>
        
      </div>
    </div>

  <div class="list">
      <div class="list_title list_title2">
        <span class="active">关于积分</span>
        <a @click="toDetail">积分规则说明>></a>
      </div>
     <div class="content_text">
      {{info.explain}}
     </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "instegralIntro",
  data() {
    return {
      headers: {
        token: localStorage.getItem("access_token"),
      },
      integral: 0,
      info: {
        task_ok_num:0
      },
      banner:{}
    }
  },
  components: {},
  mounted() {

    this.getInfo();
  },
  methods: {

    getInfo() {
      this.$api.POST(
        this.$face.integral_task,{},(res) => {
          this.info = res.data;
        }
      );
    },
    toRecord() {
       if(!this.headers.token){
        this.$parent.loginShow();
        return ;
      }
      this.$router.push({
        name: "integralRecord",
      });
    },
   //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,{},function (res) {
          that.$store.commit("userInfo", res.data);
          that.integral=res.data.integral;
        }
      );
    },
    toArrondi(){
      let routeData = this.$router.resolve({
          name: "refresh",
          query: {
            usernext: 'arrondi',
          },
        });
        window.open(routeData.href, "_blank");
    },
    toRain() {
        this.$router.push({
            name: "integralIntro",
        });
    },
   toVideo(){
      let routeData = this.$router.resolve({
          name: "refresh",
          query: {
            usernext: 'video',
          },
        });
        window.open(routeData.href, "_blank");
    },
    toDetail() {
      let routeUrl = this.$router.resolve({
        path: "/agreement",
        query: {
          id: 14,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },

};
</script>

<style scoped>
    
   .list .list_title2 span {
  cursor: initial;
}
.list .list_title2 a{ float: right; font-size: 16px; color: #999; cursor: pointer;}
.list {
  width: 1008px;
  margin: 39px auto;
}
.list .list_title {
  height: 39px;
  line-height: 39px;
  font-size: 18px;
  color: #777;
  border-bottom: 1px #e0e2e5 solid;
}
.list .list_title span {
  display: inline-block;
  padding: 0 2px;
  margin-right: 43px;
  cursor: pointer;
}
.list .list_title span.active {
  border-bottom: 2px #5857ff solid;
  height: 37px;
  line-height: 39px;
}
.list_omg {
  margin: 75px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 840px;
}
.list_omg_one {
    display: inline-block;
    width: 280px;
    text-align: center;
    margin-bottom: 55px;
}
.list_omg_one .yuan {
  width: 62px;
  height: 62px;
  background-image: linear-gradient(to right bottom, #bdbdfd, #5b5aff);
  border-radius: 50px;
  margin: auto;
  line-height: 52px;
  padding: 0;
}
.list_omg_one .yuan span{ color: #fff;}
.list_omg_one .yuan span:nth-child(1){
    font-size: 22px;
    
}
.list_omg_one .yuan span:nth-child(2){
    font-size: 12px;
}
.list_omg h2 {
  margin-top: 17px;
  font-size: 16px;
  color: #4d4d4d;
}
.list_omg span {
    display: inline-block;
  padding: 4px 0;
  font-size: 12px;
  color: #b4b4b4;
}
.list_omg .btn{ width: 67px; height: 24px; border: 1px #5957ff solid; color: #5957ff; font-size: 14px; border-radius: 20px; line-height: 24px; text-align: center; margin: auto; cursor: pointer;} 
.list_omg .btn2{ color: #b4b4b4; border: 1px #b4b4b4 solid;}
.content_text{ padding: 32px 5px; color: #4d4d4d; line-height: 35px; font-size: 15px;}
</style>